import { uiData } from './uiData'
import { Link } from 'routes/components/Shared'
import { DOCS_LINK, LINKS } from 'routes/constants'
import { LandingUICard } from './LandingUICard'
import clsx from 'clsx'
import classes from './LandingUI.module.scss'

export const LandingUI = () => {
  return (
    <section className={classes.section}>
      <h2 className={clsx('page__title', classes.title)}>
        Build integration UX your customers love
      </h2>
      <div className={clsx('flex-column', classes.wrapper)}>
        <LandingUICard item={uiData[0]} className={classes.block__default} />
        <div className={classes.stroke}>
          <span className={classes.text}>or</span>
        </div>
        <LandingUICard item={uiData[1]} className={classes.block__custom} />
      </div>

      <div className={clsx('page__actions', classes.actions)}>
        <Link
          className={'page__button'}
          type='secondary'
          href={LINKS.INTEGRATION_UX}
        >
          Learn more
        </Link>
        <Link
          className={'page__button'}
          type='secondary'
          href={`${DOCS_LINK}/ux`}
        >
          Read the Docs
        </Link>
      </div>
    </section>
  )
}
