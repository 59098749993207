import { useState } from 'react'
import { LINKS, SCENARIOS_DOCS_LINK } from 'routes/constants'
import { Link, Tabs } from 'routes/components/Shared'
import { ideData } from './ideData'
import { LandingIDECard } from './LandingIDECard'
import LogoIcon from './icons/icon-logo.svg'
import IDEMobImage1x from './images/landing-ide-mobile@1x.png'
import IDEMobImage2x from './images/landing-ide-mobile@2x.png'
import clsx from 'clsx'
import classes from './LandingIDE.module.scss'

export const LandingIDE = () => {
  const [activeId, setActiveId] = useState(ideData[1].id)
  const activeItem = ideData.find((item) => item.id === activeId)

  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.heading)}>
        <h2 className={clsx('page__title', classes.title)}>
          Ship integration scenarios 10x faster
        </h2>
        <p className={clsx('page__subtitle', classes.subtitle)}>
          Start with one of the thousands of pre-built scenario templates or
          build a new one from scratch. Focus on your business logic, not the
          implementation details.
        </p>
      </div>

      <div className={classes.visual}>
        <div className={(classes.wrapper, classes.wrapper__mobile)}>
          <picture className={classes.image}>
            <source
              media='(min-resolution: 2dppx)'
              srcSet={IDEMobImage2x.src}
            />
            <source srcSet={IDEMobImage1x.src} />
            <img
              src={IDEMobImage1x.src}
              width={480}
              height={1002}
              alt='IDE picture'
              loading='lazy'
            />
          </picture>
        </div>

        <div className={clsx(classes.wrapper, classes.wrapper__desktop)}>
          <div className={clsx('page__background', classes.background)} />
          <div className={clsx('flex-column', classes.tabs)}>
            <>
              <nav className={clsx('flex-column', classes.tabs_nav)}>
                <div className={classes.tabs_logo}>
                  <img src={LogoIcon.src} alt='Integration App Logo' />
                </div>
                <Tabs
                  className={classes.tabs_list}
                  tabsData={ideData}
                  activeId={activeId}
                  onTabClick={(tab) => setActiveId(tab)}
                />
              </nav>
              <LandingIDECard card={activeItem} />
            </>
          </div>
        </div>
      </div>

      <div className={clsx('page__actions', classes.actions)}>
        <Link
          className={clsx('page__button', classes.link)}
          type='secondary'
          href={SCENARIOS_DOCS_LINK}
        >
          Read the Docs
        </Link>
        <Link
          className={clsx('page__button', classes.link)}
          type='secondary'
          href={LINKS.SCENARIOS}
        >
          Explore pre-built Scenarios
        </Link>
      </div>
    </section>
  )
}
