// import { Link } from 'routes/components/Shared'
import VendorLockImageMob from './landing-vendor-lock-mob.svg'
import VendorLockImageDesk from './landing-vendor-lock-desk.svg'
import clsx from 'clsx'
import classes from './VendorLock.module.scss'

export const InfrastructureVendorLock = ({
  className,
}: {
  className?: string
}) => {
  return (
    <div className={clsx('flex-column', classes.wrapper, className)}>
      <div className={classes.content}>
        <div>
          <h2 className={clsx('page__title', classes.title)}>
            No vendor lock-in
          </h2>
          <div className={clsx('flex-column', classes.description)}>
            <p className={clsx('page__subtitle', classes.subtitle)}>
              Everything you build with us is highly transparent and portable.
              You have full access to your integration logic, connectors,
              customer configurations and credentials.
            </p>
            <p className={clsx('page__subtitle', classes.subtitle)}>
              If you decide to move part or all of your integrations elsewhere -
              you can do it.
            </p>
          </div>
        </div>
        {/* <Link
          className={clsx('page__button', classes.button)}
          type='secondary'
          href='#'
        >
          Learn More
        </Link> */}
      </div>
      <div className={classes.visual}>
        <picture className={classes.image}>
          <source media='(min-width: 768px)' srcSet={VendorLockImageDesk.src} />
          <source media='(max-width: 767px)' srcSet={VendorLockImageMob.src} />
          <img
            src={VendorLockImageMob.src}
            alt='No vendor lock-in'
            width={394}
            height={270}
            loading='lazy'
          />
        </picture>
      </div>
    </div>
  )
}
