import ScaleImageMob1x from './universal-integrations-mob@1x.png'
import ScaleImageMob2x from './universal-integrations-mob@2x.png'
import ScaleImageDesk1x from './universal-integrations-desk@1x.png'
import ScaleImageDesk2x from './universal-integrations-desk@2x.png'
import clsx from 'clsx'
import classes from './IntegrationsVisual.module.scss'

interface LandingScaleVisualProps {
  bgIsVisible?: boolean
}

export const IntegrationsVisual = ({
  bgIsVisible,
}: LandingScaleVisualProps) => {
  return (
    <div className={classes.visual}>
      {bgIsVisible && (
        <div className={clsx('page__background', classes.background)} />
      )}
      <div className={classes.wrapper}>
        <picture className={classes.image}>
          <source
            media='(min-resolution: 2dppx) and (min-width: 768px)'
            srcSet={ScaleImageDesk2x.src}
          />
          <source media='(min-width: 768px)' srcSet={ScaleImageDesk1x.src} />
          <source
            media='(min-resolution: 2dppx) and (max-width: 767px)'
            srcSet={ScaleImageMob2x.src}
          />
          <source media='(max-width: 767px)' srcSet={ScaleImageMob1x.src} />
          <img
            src={ScaleImageMob1x.src}
            alt='Scale your integration scenarios'
            width={480}
            height={854}
            loading='lazy'
          />
        </picture>
      </div>
    </div>
  )
}
