import { InfrastructureCertification } from './Certification/Certification'
import { InfrastructureVendorLock } from './VendorLock/VendorLock'
import clsx from 'clsx'
import classes from './LandingInfrastructure.module.scss'

export const LandingInfrastructure = () => {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.heading)}>
        <h2 className={clsx('page__title', classes.title)}>
          Self-host or use our cloud
        </h2>

        <p className={clsx('page__subtitle', classes.subtitle)}>
          Use the self-hosted version of our product or let us manage the
          infrastructure. Your choice.
        </p>
      </div>
      <InfrastructureCertification className={classes.certification} />
      <InfrastructureVendorLock />
    </section>
  )
}
