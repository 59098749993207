import IndexPage from 'routes/Index'
import { getTotalIntegrations } from 'routes/Integrations/helpers'
import { INTEGRATIONS_REVALIDATE_TIME } from 'routes/constants'

export default function WebsiteIndex({
  totalIntegrations,
}: {
  totalIntegrations: number
}) {
  return <IndexPage totalIntegrations={totalIntegrations} />
}

export async function getStaticProps() {
  // Fetch data from external API
  const totalIntegrations = await getTotalIntegrations()

  // Pass data to the page via props
  return {
    props: { totalIntegrations },
    revalidate: INTEGRATIONS_REVALIDATE_TIME, // In seconds
  }
}
