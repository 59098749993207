import { IDEDataType } from './ideData'
import classes from './LandingIDE.module.scss'

export const LandingIDECard = ({ card }: { card?: IDEDataType }) => {
  if (!card) {
    return null
  }

  const { title, imageSm, imageMd, imageLg } = card

  return (
    <div className={classes.card}>
      <picture className={classes.card_image}>
        <source
          media='(min-resolution: 2dppx) and (min-width: 1000px)'
          srcSet={imageLg.src}
        />
        <source
          media='(min-resolution: 2dppx) and (min-width: 576px) and (max-width: 999px)'
          srcSet={imageMd.src}
        />
        <source media='(min-width: 1000px)' srcSet={imageMd.src} />
        <source media='(max-width: 999px)' srcSet={imageSm.src} />
        <img src={imageSm.src} alt={title} loading='eager' />
      </picture>
    </div>
  )
}
