import { UIDataType } from './uiData'
import clsx from 'clsx'
import classes from './LandingUI.module.scss'

export const LandingUICard = ({
  item,
  className,
}: {
  item: UIDataType
  className?: string
}) => {
  const { title, image1x, image2x } = item

  return (
    <div className={clsx('flex-column', classes.block, className)}>
      <h3 className={clsx('page__title', classes.block_title)}>{title}</h3>
      <div className={classes.block_visual}>
        <picture className={classes.block_image}>
          <source media='(min-resolution: 2dppx)' srcSet={image2x.src} />
          <source srcSet={image1x.src} />
          <img
            src={image1x.src}
            alt={title}
            width={464}
            height={424}
            loading='lazy'
          />
        </picture>
      </div>
    </div>
  )
}
