import DevelopmentMobImage1x from './landing-development-mob@1x.png'
import DevelopmentMobImage2x from './landing-development-mob@2x.png'
import DevelopmentDeskImage1x from './landing-development-desk@1x.png'
import DevelopmentDeskImage2x from './landing-development-desk@2x.png'
import clsx from 'clsx'
import classes from './LandingDevelopment.module.scss'

export const LandingDevelopment = () => {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.heading)}>
        <h2 className={clsx('page__title', classes.title)}>
          Deploy, test, and version integrations in full sync with your product
        </h2>
        <p className={clsx('page__subtitle', classes.subtitle)}>
          Our integration engine is API-first, fully observable, and seamlessly
          fits into your existing infrastructure and processes.
        </p>
      </div>

      <div className={classes.visual}>
        <div className={clsx('page__background', classes.background)} />
        <div className={classes.wrapper}>
          <div className={clsx('page__glow', classes.glow)} />
          <picture className={classes.image}>
            <source
              media='(min-resolution: 2dppx) and (min-width: 768px)'
              srcSet={DevelopmentDeskImage2x.src}
            />
            <source
              media='(min-width: 768px)'
              srcSet={DevelopmentDeskImage1x.src}
            />
            <source
              media='(min-resolution: 2dppx) and (max-width: 767px)'
              srcSet={DevelopmentMobImage2x.src}
            />
            <source
              media='(max-width: 767px)'
              srcSet={DevelopmentMobImage1x.src}
            />
            <img
              src={DevelopmentMobImage1x.src}
              alt='Integration Engine'
              width={480}
              height={781}
              loading='lazy'
            />
          </picture>
        </div>
      </div>
    </section>
  )
}
