import { Container, DefaultPageLayout } from 'routes/components/Layout'
import { LandingHero } from './components/Hero/LandingHero'
import { LandingCompanies } from './components/Companies/LandingCompanies'
import { LandingNetwork } from './components/Network/LandingNetwork'
import { LandingInteraction } from './components/Interaction/LandingInteraction'
import { LandingIDE } from './components/IDE/LandingIDE'
import { LandingIntegrations } from './components/Integrations/LandingIntegrations'
import { LandingUI } from './components/UI/LandingUI'
import { LandingDevelopment } from './components/Development/LandingDevelopment'
import { LandingInfrastructure } from './components/Infrastructure/LandingInfrastructure'
import { LandingBestSupport } from './components/BestSupport/LandingBestSupport'
import classes from './index.module.scss'

export default function IndexPage({
  totalIntegrations,
}: {
  totalIntegrations: number
}) {
  return (
    <DefaultPageLayout className={classes.page}>
      <Container className={classes.page_container}>
        <LandingHero />
        <LandingCompanies />
        <div className={classes.page_wrapper}>
          <LandingNetwork totalIntegrations={totalIntegrations} />
          <LandingIntegrations />
          <LandingIDE />
          <LandingInteraction />
          <LandingUI />
          <LandingDevelopment />
          <LandingInfrastructure />
        </div>
        <LandingBestSupport />
      </Container>
    </DefaultPageLayout>
  )
}
