import { LINKS, DOCS_LINK, CONSOLE_LOGIN_LINK } from 'routes/constants'
import { Link, BookCalendlyButton } from 'routes/components/Shared'
import HeroVideoMobileMp4 from './landing-hero-mob.mp4'
import HeroVideoMobileWebm from './landing-hero-mob.webm'
import HeroImageMobile from './landing-hero-mob.jpg'
import HeroVideoDesktopMp4 from './landing-hero-desk.mp4'
import HeroVideoDesktopWebm from './landing-hero-desk.webm'
import HeroImageDesktop from './landing-hero-desk.jpg'
import clsx from 'clsx'
import classes from './LandingHero.module.scss'

export const LandingHero = () => {
  return (
    <section className={classes.section}>
      <div className={clsx('flex-column', classes.heading)}>
        <h1 className={classes.title}>
          Universal Integration Layer for your SaaS product
        </h1>
        <p className={clsx('page__subtitle', classes.subtitle)}>
          Connect to any app your customers use. Build seamless integration
          logic and UI. Scale it to every relevant app with AI.
        </p>
        <Link
          className={classes.link}
          type='noLineGradient'
          href={LINKS.HOW_WE_ARE_DIFFERENT}
        >
          How is it different from embedded iPaaS or unified API?
        </Link>
      </div>

      <Actions />

      <div className={classes.visual}>
        <HeroVideo />
      </div>
    </section>
  )
}

const Actions = () => {
  return (
    <div className={clsx('page__actions', classes.actions)}>
      <Link
        className={clsx('page__button', classes.button, classes.button__try)}
        type='primary'
        href={CONSOLE_LOGIN_LINK}
      >
        Try it Free
      </Link>

      <Link
        className={clsx('page__button', classes.button, classes.button__read)}
        type='secondary'
        href={DOCS_LINK}
      >
        Read Documentation
      </Link>

      <BookCalendlyButton
        className={clsx('page__button', classes.button, classes.button__book)}
        category='secondary'
        isReversed
      >
        <span>Schedule Demo</span>
      </BookCalendlyButton>
    </div>
  )
}

const HeroVideo = () => {
  return (
    <>
      <div className={clsx('flex-column', classes.video__desktop)}>
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
          controls={false}
          poster={HeroImageDesktop.src}
          fetchpriority='high'
        >
          <source
            src={HeroVideoDesktopWebm}
            media='(min-width:768px)'
            type='video/webm'
          />
          <source
            src={HeroVideoDesktopMp4}
            media='(min-width:768px)'
            type='video/mp4'
          />
          <img
            src={HeroImageDesktop.src}
            loading='eager'
            aria-hidden='true'
            width={1000}
            height={452}
            alt=''
          />
        </video>
      </div>
      <div className={classes.video__mobile}>
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
          controls={false}
          poster={HeroImageMobile.src}
          fetchpriority='high'
        >
          <source
            src={HeroVideoMobileWebm}
            media='(max-width:767px)'
            type='video/webm'
          />
          <source
            src={HeroVideoMobileMp4}
            media='(max-width:767px)'
            type='video/mp4'
          />
          <img
            src={HeroImageMobile.src}
            loading='eager'
            width={480}
            height={720}
            aria-hidden='true'
            alt=''
          />
        </video>
      </div>
    </>
  )
}
